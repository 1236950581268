import * as Yup from "yup";

export const validationFirstName = Yup.string()
  .min(1, "Too Short!")
  .max(255, "Too Long!")
  .required("Please enter a first name");

export const validationLastName = Yup.string()
  .min(1, "Too Short!")
  .max(255, "Too Long!")
  .required("Please enter a last name");

export const validationEmail = Yup.string()
  .required("Please enter an email")
  .matches(/^[^\s@,;:]+@[^\s@]+\.[^\s@]+$/, "Invalid email address");

export const validationUKPhoneNumber = Yup.string().matches(
  /^(\+44|0)\d{10}$/,
  "Please enter a valid UK phone number",
);

export const validationUKPostcode = Yup.string().matches(
  /\b((?:(?:gir)|(?:[a-pr-uwyz])(?:(?:[0-9](?:[a-hjkpstuw]|[0-9])?)|(?:[a-hk-y][0-9](?:[0-9]|[abehmnprv-y])?)))) ?([0-9][abd-hjlnp-uw-z]{2})\b/i,
  "Please enter a valid UK post code",
);
