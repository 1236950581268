"use client";

import TextInputField from "@/components/ui/Form/TextInputField";
import { validationEmail } from "@/helpers/validation-helpers";
import { Button } from "@cgc/ui/button";
import { Heading, Paragraph } from "@cgc/ui/typography";
import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { Form, Formik, FormikHelpers } from "formik";
import Image from "next/image";
import { useRouter, useSearchParams } from "next/navigation";
import { useState } from "react";
import * as Yup from "yup";

/**
 * Props for `EmailStep`.
 */
export type EmailStepProps = SliceComponentProps<Content.EmailStepSlice>;

export interface EmailStepForm {
  email: string;
}

/**
 * Component for "EmailStep" Slices.
 */
const EmailStep = ({ slice }: EmailStepProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validationSchema = Yup.object<EmailStepForm>({
    email: validationEmail,
  });

  const router = useRouter();

  const searchParams = useSearchParams();
  const discountCode = searchParams.get("discount_code");

  const handleFormSubmit = (
    values: EmailStepForm,
    formikHelpers: FormikHelpers<EmailStepForm>,
  ) => {
    try {
      const url = `https://club.craftginclub.co.uk/pages/direct-to-checkout?discount_code=${discountCode}&email=${values.email}`;
      formikHelpers.setSubmitting(false);
      router.push(url);
    } catch (error) {}
  };

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <div
        id="email-step"
        className="m-6 flex flex-col gap-6 sm:h-[800px] md:m-0 md:grid md:grid-cols-2 md:gap-0"
      >
        <div className="flex w-auto flex-col items-center justify-center md:w-full">
          <div>
            <Heading
              size="h1"
              className="text-cgc-blue mb-6 block text-center md:hidden md:text-left"
            >
              {slice.primary.heading}
            </Heading>
          </div>

          <div className="border-cgc-blue max-w-[385px] rounded-lg border-2 p-3 shadow-lg">
            <span
              className="mb-3 grid"
              style={{ gridTemplateColumns: "70% 15% 15%" }}
            >
              <Paragraph className="text-xl font-bold">
                {slice.primary.product_title}
              </Paragraph>

              <Paragraph size="p1" className="line-through">
                {slice.primary.price_old ? `£${slice.primary.price_old}` : null}
              </Paragraph>
              {slice.primary.price_new > 0 ? (
                <Paragraph size="p1">£{slice.primary.price_new}</Paragraph>
              ) : (
                <Paragraph size="p1" className="text-cgc-green font-bold">
                  FREE
                </Paragraph>
              )}
            </span>
            <div className="gap-3">
              <Image
                src={slice.primary.product_image.url}
                alt={slice.primary.product_image.url}
                width={1500}
                height={1500}
                className="mb-3 h-[240px] rounded-md object-cover"
              />
              <Paragraph size="p1" style={{ color: "rgba(142, 142, 142, 1)" }}>
                {slice.primary.product_description}
              </Paragraph>
            </div>
          </div>
        </div>

        <div className="flex w-auto items-center justify-center md:w-full md:bg-neutral-100">
          <div className="grid h-fit max-w-[504px] gap-4 md:px-12">
            <div>
              <Heading
                size="h1"
                className="text-cgc-blue hidden text-center md:block"
              >
                {slice.primary.heading}
              </Heading>
            </div>
            <Formik<EmailStepForm>
              initialValues={{ email: "" }}
              validationSchema={validationSchema}
              onSubmit={handleFormSubmit}
            >
              {({ isValid, isSubmitting }) => (
                <Form className="flex flex-col gap-4">
                  <TextInputField
                    placeholder="Enter your email address here"
                    className="text-center"
                    name="email"
                  />
                  <Button
                    color="primary"
                    className=""
                    type="submit"
                    isLoading={isLoading && isValid}
                    disabled={isSubmitting}
                    onClick={() => {
                      if (isValid) setIsLoading(true);
                    }}
                  >
                    {slice.primary.button_text}
                  </Button>
                </Form>
              )}
            </Formik>
            <div className="text-cgc-blue grid gap-4">
              <Heading size="h2" className="text-center">
                {slice.primary.upsell_heading}
              </Heading>
              {slice.primary.upsell_points.map((point) => (
                <Paragraph size="p1" key={point.copy}>
                  ✔ {point.copy}
                </Paragraph>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmailStep;
